<template>
  <div
    v-if="showModal"
    class="modal fixed w-full h-full top-0 left-0 flex items-center justify-center z-50"
  >
    <!-- Overlay -->
    <div
      class="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"
    ></div>

    <!-- Modal Container -->
    <div
      class="modal-container bg-white w-1/2 h-1/2 mx-auto rounded shadow-lg z-50 overflow-hidden"
    >
      <!-- Modal Content -->
      <div class="modal-content text-left flex flex-col h-full">
        <!-- Title -->
        <div class="flex justify-between items-center bg-blue-800 p-4">
          <p class="text-2xl font-bold text-white">
            {{ $t("comment_modal.header") }}
          </p>
          <div class="modal-close cursor-pointer z-50" @click="onClose">
            <svg
              class="fill-current text-white"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path
                d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
              />
            </svg>
          </div>
        </div>

        <!-- Body -->
        <div class="p-4 flex-grow overflow-y-auto">
          <p v-if="comments.length === 0">
            {{ $t("comment_modal.data.no_data") }}
          </p>
          <div
            v-else
            v-for="(comment, index) in comments"
            :key="index"
            class="w-full pb-2"
          >
            <div
              class="grid grid-cols-6 py-2 break-words border-b-2 border-gray-300 border-solid"
            >
              <!-- First row: user -->
              <p class="col-span-1 font-bold">
                {{ $t("comment_modal.data.labels.user") }}
              </p>
              <p class="col-span-5">{{ comment.user }}</p>
              <!-- Second row: created_at -->
              <p class="col-span-1 font-bold">
                {{ $t("comment_modal.data.labels.date") }}
              </p>
              <p class="col-span-5">{{ comment.created_at }}</p>
              <!-- Third row: text -->
              <p class="col-span-1 font-bold">
                {{ $t("comment_modal.data.labels.description") }}
              </p>
              <p class="col-span-5">{{ comment.text }}</p>
            </div>
          </div>
        </div>

        <div class="w-full px-4 mt-4">
          <textarea
            id="comment-input"
            v-model="text"
            :placeholder="$t('comment_modal.input.text.placeholder')"
            class="w-full mt-1 p-2 border border-solid border-black rounded-md"
          />
        </div>

        <!-- Footer -->
        <div class="flex justify-end p-4">
          <button
            class="px-4 bg-transparent p-3 text-blue-800 hover:bg-gray-100 hover:text-indigo-400 mr-2"
            @click="close"
          >
            {{ $t("comment_modal.buttons.close") }}
          </button>
          <button
            class="modal-close px-4 bg-blue-800 p-3 text-white hover:bg-indigo-400"
            @click="submit"
          >
            {{ $t("comment_modal.buttons.submit") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CommentModal",
  props: {
    comments: {
      type: Array
    },
    showModal: {
      type: Boolean
    },
    onClose: {
      type: Function
    },
    onSubmit: {
      type: Function
    }
  },
  data() {
    return {
      text: ""
    };
  },
  methods: {
    close() {
      this.text = "";
      this.onClose();
    },
    submit() {
      this.onSubmit(this.text);
      this.text = "";
    }
  }
};
</script>
