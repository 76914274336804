<template>
  <div class="flex flex-col w-full items-start">
    <p
      :class="`${isPdf ? 'text-xs' : 'text-xl'} font-bold  text-blue-800 text-center`"
    >
      {{ $t("production_order_detail.table.title_garment") }}
    </p>
    <!-- Loading state -->
    <div
      v-if="adjustmentsLoading"
      :class="
        `${
          isPdf ? 'adjustments-table-pdf' : 'adjustments-table'
        } table-fixed w-auto border-solid border-gray-200 border-2 mt-3 overflow-y-auto`
      "
    >
      <p class="text-lg">
        {{ $t("production_order_detail.table.loading") }}
      </p>
    </div>

    <!-- Table when data exists -->
    <table
      v-else-if="adjustments.length > 0"
      :class="
        `${
          isPdf ? 'adjustments-table-pdf' : 'adjustments-table'
        } table-fixed w-auto border-solid border-gray-200 border-2 mt-3 overflow-y-auto`
      "
    >
      <thead>
        <tr
          :class="`${isPdf ? 'text-xs' : 'text-sm'} bg-gray-200 text-blue-800`"
        >
          <th class="w-2/12 px-2">
            {{ $t("production_order_detail.table.headers.size_item") }}
          </th>
          <th class="w-2/12 px-2">
            {{
              $t("production_order_detail.table.headers.garment_measurements")
            }}
          </th>
          <th :class="`${isRtwTailoring ? 'w-1/12' : 'w-3/12'} px-2`">
            {{ $t("production_order_detail.table.headers.drop_adjustments") }}
          </th>
          <th class="w-2/12 px-2">
            {{ $t("production_order_detail.table.headers.fit_adjustments") }}
          </th>
          <th :class="`${isRtwTailoring ? 'w-2/12' : 'w-3/12'} px-2`">
            {{ $t("production_order_detail.table.headers.production_order") }}
          </th>
          <th v-if="isRtwTailoring" class="w-2/12 px-2">
            {{
              $t("production_order_detail.table.headers.product_measurements")
            }}
          </th>
          <th v-if="isRtwTailoring" class="w-1/12 px-2">
            {{ $t("production_order_detail.table.headers.alterations") }}
          </th>
          <th />
        </tr>
      </thead>

      <tbody>
        <tr
          v-for="(adjustment, index) in adjustments"
          :key="index"
          :class="`${isPdf ? 'text-xs' : ''}`"
          :style="
            isPdf
              ? {
                  borderBottom: '1px solid #edf2f7',
                  height: '28px'
                }
              : {}
          "
        >
          <td>{{ adjustment.size_item }}</td>
          <td>{{ adjustment.garment_measurement }}</td>
          <td>
            {{
              adjustment.calculated_drop !== "0.0"
                ? adjustment.calculated_drop
                : "-"
            }}
          </td>
          <td
            :style="
              isPdf
                ? {
                    height: '28px'
                  }
                : {}
            "
          >
            <template v-if="!isPdf">
              <input
                :id="`fit_adjustment_${index}`"
                type="number"
                v-model="fitAdjustments[index].value"
                step="0.1"
                class="w-20 mt-1 py-2 pl-2 text-center border-2 border-solid border-black rounded-md"
              />
            </template>
            <template v-else>
              {{ fitAdjustments[index].value || "0" }}
            </template>
          </td>
          <td>{{ getProductionOrderValue(index) }}</td>
          <td v-if="isRtwTailoring">
            {{ adjustment.product_measurement }}
          </td>
          <td v-if="isRtwTailoring">
            {{ getAlterationValue(index, adjustment.product_measurement) }}
          </td>
        </tr>
      </tbody>
    </table>

    <!-- Error/No data states -->
    <div v-else>
      <p v-if="adjustmentsLoading === false && adjustmentsError === ''">
        {{ $t("production_order_detail.table.no_data") }}
      </p>
      <p v-else-if="adjustmentsLoading === false && adjustmentsError !== ''">
        {{ adjustmentsError }}
      </p>
      <i v-else class="fas fa-circle-notch fa-spin fa-5x mt-5"></i>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductionOrderGarmentTable",
  props: {
    adjustments: {
      type: Array,
      required: true
    },
    fitAdjustments: {
      type: Array,
      required: true
    },
    adjustmentsLoading: {
      type: Boolean,
      default: false
    },
    adjustmentsError: {
      type: String,
      default: ""
    },
    isRtwTailoring: {
      type: Boolean,
      default: false
    },
    isPdf: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    getProductionOrderValue(index) {
      const fitAdjustment = parseFloat(this.fitAdjustments[index].value) || 0;
      const adjustment = this.adjustments[index];

      return (
        parseFloat(adjustment.garment_measurement) +
        parseFloat(adjustment.calculated_drop) +
        fitAdjustment
      ).toFixed(1);
    },

    getAlterationValue(index, productSize) {
      const productionOrderValue = this.getProductionOrderValue(index);
      return parseFloat(productionOrderValue - productSize).toFixed(1);
    }
  }
};
</script>
