var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-col w-full items-start"},[_c('p',{class:`${_vm.isPdf ? 'text-xs' : 'text-xl'} font-bold  text-blue-800 text-center`},[_vm._v(" "+_vm._s(_vm.$t("production_order_detail.table.title_body"))+" ")]),(_vm.adjustmentsLoading)?_c('div',{class:`${
        _vm.isPdf ? 'adjustments-table-pdf' : 'adjustments-table'
      } table-fixed w-auto border-solid border-gray-200 border-2 mt-3 overflow-y-auto`},[_c('p',{staticClass:"text-lg"},[_vm._v(" "+_vm._s(_vm.$t("production_order_detail.table.loading"))+" ")])]):(_vm.adjustments.length > 0)?_c('table',{class:`${
        _vm.isPdf ? 'adjustments-table-pdf' : 'adjustments-table'
      } table-fixed w-auto border-solid border-gray-200 border-2 mt-3 overflow-y-auto`},[_c('thead',[_c('tr',{class:`${_vm.isPdf ? '' : 'text-sm'} bg-blue-800 text-white`},[_c('th',{staticClass:"w-3/12 px-2"},[_vm._v(" "+_vm._s(_vm.$t("production_order_detail.table.headers.size_item"))+" ")]),_c('th',{staticClass:"w-3/12 px-2"},[_vm._v(" "+_vm._s(_vm.$t("production_order_detail.table.headers.body_measurements"))+" ")]),_c('th',{staticClass:"w-2/12 px-2"},[_vm._v(" "+_vm._s(_vm.$t("production_order_detail.table.headers.fit_specifications"))+" ")]),_c('th',{staticClass:"w-3/12 px-2"},[_vm._v(" "+_vm._s(_vm.$t("production_order_detail.table.headers.garment_measurements"))+" ")])])]),_c('tbody',_vm._l((_vm.adjustments),function(adjustment,index){return _c('tr',{key:index,class:`${_vm.isPdf ? 'text-xs' : ''}`,style:(_vm.isPdf
            ? {
                borderBottom: '1px solid #edf2f7',
                height: '28px'
              }
            : {})},[_c('td',[_vm._v(_vm._s(adjustment.body_size_item))]),_c('td',[_vm._v(_vm._s(adjustment.body_measurement))]),_c('td',[_vm._v(_vm._s(adjustment.fit_specification))]),_c('td',[_vm._v(" "+_vm._s(_vm.getDesiredGarmentMeasurement(index))+" ")])])}),0)]):_c('div',[(_vm.adjustmentsLoading === false && _vm.adjustmentsError === '')?_c('p',[_vm._v(" "+_vm._s(_vm.$t("production_order_detail.table.no_data"))+" ")]):(_vm.adjustmentsLoading === false && _vm.adjustmentsError !== '')?_c('p',[_vm._v(" "+_vm._s(_vm.adjustmentsError)+" ")]):_c('i',{staticClass:"fas fa-circle-notch fa-spin fa-5x mt-5"})])])
}
var staticRenderFns = []

export { render, staticRenderFns }