<template>
  <div class="flex flex-col w-full items-start">
    <p
      :class="`${isPdf ? 'text-xs' : 'text-xl'} font-bold  text-blue-800 text-center`"
    >
      {{ $t("production_order_detail.table.title_body") }}
    </p>
    <!-- Loading state -->
    <div
      v-if="adjustmentsLoading"
      :class="
        `${
          isPdf ? 'adjustments-table-pdf' : 'adjustments-table'
        } table-fixed w-auto border-solid border-gray-200 border-2 mt-3 overflow-y-auto`
      "
    >
      <p class="text-lg">
        {{ $t("production_order_detail.table.loading") }}
      </p>
    </div>

    <!-- Table when data exists -->
    <table
      v-else-if="adjustments.length > 0"
      :class="
        `${
          isPdf ? 'adjustments-table-pdf' : 'adjustments-table'
        } table-fixed w-auto border-solid border-gray-200 border-2 mt-3 overflow-y-auto`
      "
    >
      <thead>
        <tr :class="`${isPdf ? '' : 'text-sm'} bg-blue-800 text-white`">
          <th class="w-3/12 px-2">
            {{ $t("production_order_detail.table.headers.size_item") }}
          </th>
          <th class="w-3/12 px-2">
            {{ $t("production_order_detail.table.headers.body_measurements") }}
          </th>
          <th class="w-2/12 px-2">
            {{ $t("production_order_detail.table.headers.fit_specifications") }}
          </th>
          <th class="w-3/12 px-2">
            {{
              $t("production_order_detail.table.headers.garment_measurements")
            }}
          </th>
        </tr>
      </thead>

      <tbody>
        <tr
          v-for="(adjustment, index) in adjustments"
          :key="index"
          :class="`${isPdf ? 'text-xs' : ''}`"
          :style="
            isPdf
              ? {
                  borderBottom: '1px solid #edf2f7',
                  height: '28px'
                }
              : {}
          "
        >
          <td>{{ adjustment.body_size_item }}</td>
          <td>{{ adjustment.body_measurement }}</td>
          <td>{{ adjustment.fit_specification }}</td>
          <td>
            {{ getDesiredGarmentMeasurement(index) }}
          </td>
        </tr>
      </tbody>
    </table>

    <!-- Error/No data states -->
    <div v-else>
      <p v-if="adjustmentsLoading === false && adjustmentsError === ''">
        {{ $t("production_order_detail.table.no_data") }}
      </p>
      <p v-else-if="adjustmentsLoading === false && adjustmentsError !== ''">
        {{ adjustmentsError }}
      </p>
      <i v-else class="fas fa-circle-notch fa-spin fa-5x mt-5"></i>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductionOrderBodyTable",
  props: {
    adjustments: {
      type: Array,
      required: true
    },
    adjustmentsLoading: {
      type: Boolean,
      default: false
    },
    adjustmentsError: {
      type: String,
      default: ""
    },
    isPdf: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    getDesiredGarmentMeasurement(index) {
      const adjustment = this.adjustments[index];

      return (
        parseFloat(adjustment.body_measurement) +
        parseFloat(adjustment.fit_specification)
      ).toFixed(1);
    }
  }
};
</script>

<style scoped></style>
